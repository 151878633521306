<template>
  <section>
    <div class="richtext">
      <h2><strong>Introduction of Diaphragm Pumps</strong></h2>
      <p><br /></p>
      <p>
        The Diaphragm Pump of NEWDOSE can make the operation of it more safe and
        reliable by adding auxiliary valves and adopting double diaphragms with
        alarms. However, it has some disadvantages. The diaphragms of Diaphragm
        Pumps are easy to break, especially the fixed stroke diaphragm pumps.
        Once its diaphragms break, the metering fluid and the hydraulic oil
        system will pollute each other and cause damages, which will bring grave
        consequences. Let’s take a look at what is the structure principle of
        the Diaphragm Pumps.
      </p>
      <p><br /></p>
      <h2><strong>The Structure Principle Of The Diaphragm Pumps</strong></h2>
      <p><br /></p>
      <p>
        The Diaphragm Pumps of <a href="/" target="_self">NEWDOSE</a> are
        composed of a motor, drive mechanism, and pump head. The rotation
        generated by the motor will become the reciprocating motion of the worm
        gear through gearing, thereby completing the working process of suction
        and discharge. Adjust tappet motion through turning the regulating
        handwheels, and achieve the purpose of flow changing through changing
        the telescopic distance of diaphragms. The mechanical pump of NEWDOSE
        has the features of no leakage, high precision of measurement, and low
        noise, and it’s used broadly in the industries of oil, chemical,
        pharmacy, textile, and so on.
      </p>
      <p><br /></p>
      <p>
        Because of the isolating effect of the diaphragms, electric diaphragms
        pumps structurally achieves the actual isolation between the measured
        fluid and the drive lubrication mechanism. The high-tech structural
        design and the selection of new materials have greatly increased the
        service life of the diaphragm, coupled with the excellent corrosion
        resistance of PTFE (Teflon), Diaphragm Pumps now become the main type of
        pump in fluid metering applications.
      </p>
      <p><br /></p>
      <p>
        The
        <a href="/products/mechanical-dosing-pump.html" target="_self"
          >mechanical diaphragm metering pump</a
        >
        of NEWDOSE uses damping stroke slide bars, which not only keep the
        advantages of traditional spring reset metering pumps, such as the
        simple structure, fewer faults, and easy to maintenance, but also
        overcome the fatal weakness caused by the invalid eccentric travel, such
        as the big pipeline impact, high mechanical vibration, broken materials,
        flow linearity difference, loud noise, and so on.
      </p>
      <p><br /></p>
      <h2><strong>Advantages of Mechanical Diaphragm Pumps</strong></h2>
      <p><br /></p>
      <p>1. Low cost</p>
      <p>2. No dynamic seal, no leakage</p>
      <p>
        3. Can transport high viscosity medium, abrasive slurry, and hazardous
        chemicals
      </p>
      <p>4. High tolerance of the PTFE diaphragms</p>
      <p>
        5. The maximum outlet pressure is 3MPa, and the maximum flow range is
        6,000 liters per hour; The accuracy of measurements is ± 5%, and the
        variation of flow can be as much as 10% when the pressure goes from
        minimum to maximum.
      </p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Diaphragm Pump",
}
</script>